export const PREPRINTS = [
  {
    name: 'Access Microbiology',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'AfricArXiv',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'agriRxiv',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'AIJR Preprints',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'ARPHA Preprints',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'arXiv',
    abstractIndexed: false,
    fulltextIndexed: true,
    funderfulltextIndexed: false,
    url: '//arxiv.org/',
    imageUrl: 'arXiv.png',
    imageWidth: '200',
  },
  {
    name: 'Authorea Preprints',
    abstractIndexed: true,
    fulltextIndexed: true,
    funderfulltextIndexed: false,
  },
  {
    name: 'Beilstein Archives',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
    // url: '//www.beilstein-archives.org/xiv/home',
    // imageUrl: 'beilstein.svg',
    // imageWidth: '300'
  },
  {
    name: 'BioHackrXiv',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'bioRxiv',
    abstractIndexed: true,
    fulltextIndexed: true,
    funderfulltextIndexed: 'CC - licensed only',
    url: '//www.biorxiv.org/',
    imageUrl: 'bioRxiv.svg',
    imageWidth: '240',
  },
  {
    name: 'ChemRxiv',
    abstractIndexed: true,
    fulltextIndexed: true,
    funderfulltextIndexed: false,
    url: '//chemrxiv.org/',
    imageUrl: 'chemRxiv.svg',
    imageWidth: '260',
  },
  {
    name: 'EcoEvoRxiv',
    abstractIndexed: true,
    fulltextIndexed: false,
  },
  {
    name: 'Emerald Open Research',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'F1000 Research',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
    // publisher: 'F1000Res',
    // url: '//f1000research.com/',
    // imageUrl: 'F1000Res.svg',
    // imageWidth: '300'
  },
  {
    name: 'Gates Open Research',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'Health Open Research',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'HRB Open Research',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'MedEdPublish',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'medRxiv',
    abstractIndexed: true,
    fulltextIndexed: true,
    funderfulltextIndexed: 'CC - licensed only',
    url: '//www.medrxiv.org/',
    imageUrl: 'medRxiv.svg',
    imageWidth: '262',
  },
  {
    name: 'MetaArXiv',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'NIHR Open Research',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'Open Research Africa',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'Open Research Europe',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'PaleorXiv',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'PeerJ Preprints',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
    // url: '//peerj.com/preprints/',
    // imageUrl: 'peerj.svg',
    // imageWidth: '138'
  },
  {
    name: 'Preprints.org',
    abstractIndexed: true,
    fulltextIndexed: true,
    funderfulltextIndexed: false,
    // url: '//www.preprints.org/',
    // imageUrl: 'preprints.svg',
    // imageWidth: '264'
  },
  {
    name: 'PsyArXiv',
    abstractIndexed: true,
    fulltextIndexed: true,
    funderfulltextIndexed: false,
  },
  {
    name: 'Qeios',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'Research Square',
    abstractIndexed: true,
    fulltextIndexed: true,
    funderfulltextIndexed: true,
    url: '//www.researchsquare.com/browse',
    imageUrl: 'researchsquare.svg',
    imageWidth: '264',
  },
  {
    name: 'SciELO Preprints',
    abstractIndexed: true,
    fulltextIndexed: true,
    funderfulltextIndexed: false,
  },
  {
    name: 'ScienceOpen Preprints',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'SSRN',
    abstractIndexed: false,
    fulltextIndexed: true,
    funderfulltextIndexed: false,
    url: '//www.ssrn.com/index.cfm/en/',
    imageUrl: 'SSRN.svg',
    imageWidth: '240',
  },
  {
    name: 'VeriXiv',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
  },
  {
    name: 'Wellcome Open Research',
    abstractIndexed: true,
    fulltextIndexed: false,
    funderfulltextIndexed: false,
    // publisher: 'Wellcome Open Res',
    // url: '//wellcomeopenresearch.org/',
    // imageUrl: 'wor.svg',
    // imageWidth: '300'
  },
]
