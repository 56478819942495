import { PREPRINTS } from './preprints'

export const SECTIONS = [
  {
    id: 'keywordsOrTopics',
    heading: 'h3',
    title: 'Enter free text word(s) or phrase',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
  },
  {
    id: 'publicationTypeOrJournalOrArticleId',
    heading: 'h3',
    title: 'Find publication type, journal or article ID',
    type: 'primary',
    collapsible: true,
    visible: true,
    highlight: true,
    scroll: true,
    disabled: true,
  },
  /*{
    id: 'biologicalAndChemicalEntities',
    heading: 'h3',
    title:
      'Find biological & chemical entities (genes, proteins, diseases, etc.)',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
    disabled: true,
  },
  {
    id: 'dateTypeSourceId',
    heading: 'h3',
    title: 'Apply date, type, source or ID',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
    disabled: true,
  },
  {
    id: 'authorAffiliationJournalPublisher',
    heading: 'h3',
    title: 'Add author, affiliation, journal or publisher',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
    disabled: true,
  },
  {
    id: 'fundingLicensingFullTextAvailability',
    heading: 'h3',
    title: 'Specify funding, licensing, or full text availability',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
    disabled: true,
  },*/
]
export const PLACES_TO_SEARCH = [
  {
    id: 'title',
    label: 'Title',
    value: 'TITLE',
    information:
      'This search looks only within the titles of articles, books, etc., and tends to return more relevant results.',
    selected: false,
  },
  {
    id: 'abstract',
    label: 'Abstract',
    value: 'ABSTRACT',
    information:
      'This search looks only within the abstract of articles, books, etc., and tends to return more relevant results.',
    selected: false,
  },
  {
    id: 'fullText',
    label: 'All fields + Full text',
    value: '',
    information:
      'This search looks within all key article metadata fields, e.g. title, abstract, MeSH, author, affiliation, etc., plus the full text when available. This tends to expand the number of results.',
    selected: false,
  },
]
export const ARTICLE_SECTION_OPTIONS = [
  {
    value: 'ABBR',
    text: 'Abbreviations',
    selected: false,
  },
  {
    value: 'ACK_FUND',
    text: 'Acknowledgement & funding',
    selected: false,
  },
  {
    value: 'APPENDIX',
    text: 'Appendix',
    selected: false,
  },
  {
    value: 'AUTH_CON',
    text: 'Author Contribution',
    selected: false,
  },
  {
    value: 'CASE',
    text: 'Case Study',
    selected: false,
  },
  {
    value: 'COMP_INT',
    text: 'Competing Interest',
    selected: false,
  },
  {
    value: 'CONCL',
    text: 'Conclusion & Future Work',
    selected: false,
  },
  {
    value: 'DATA_AVAILABILITY',
    text: 'Data Availability',
    selected: false,
  },
  {
    value: 'DISCUSS',
    text: 'Discussion',
    selected: false,
  },
  {
    value: 'FIG',
    text: 'Figures',
    selected: false,
  },
  {
    value: 'INTRO',
    text: 'Introduction & Background',
    selected: false,
  },
  {
    value: 'METHODS',
    text: 'Materials & Methods',
    selected: false,
  },
  {
    value: 'OTHER',
    text: 'Other',
    selected: false,
  },
  {
    value: 'REF',
    text: 'References',
    selected: false,
  },
  {
    value: 'RESULTS',
    text: 'Results',
    selected: false,
  },
  {
    value: 'SUPPL',
    text: 'Supplementary Data',
    selected: false,
  },
  {
    value: 'TABLE',
    text: 'Tables',
    selected: false,
  },
]
export const ALL_PUB_TYPE_OPTIONS = [
  {
    value: '(SRC:(MED OR PMC OR AGR OR CBA) NOT PUB_TYPE:(Review))',
    text: 'Research/Journal articles',
    selected: false,
  },
  { value: 'SRC:(PPR)', text: 'Preprints', selected: false },
  { value: 'HAS_BOOK:Y', text: 'Books & documents', selected: false },
  { value: 'PUB_TYPE:(Review)', text: 'Review articles', selected: false },
  {
    value: 'PUB_TYPE:(“Systematic review”)',
    text: 'Systematic reviews',
    selected: false,
  },
  {
    value: 'PUB_TYPE:("Clinical Trial")',
    text: 'Clinical trial',
    selected: false,
  },
  { value: 'PUB_TYPE:(Guideline)', text: 'Guidelines', selected: false },
  {
    value: 'PUB_TYPE:("Retracted Publication")',
    text: 'Retracted journal article',
    selected: false,
  },
  {
    value: 'PUB_TYPE:("Preprint removal" OR "Preprint withdrawal")',
    text: 'Removed or withdrawn preprint',
    selected: false,
  },
]

export const PUB_TYPE_PUBMED_NCBI_OPTIONS = [
  {
    value: 'adaptive clinical trial',
    text: 'adaptive clinical trial',
    selected: false,
  },
  { value: 'address', text: 'address', selected: false },
  {
    value: 'autobiography',
    text: 'autobiography',
    selected: false,
  },
  {
    value: 'bibliography',
    text: 'bibliography',
    selected: false,
  },
  {
    value: 'biography',
    text: 'biography',
    selected: false,
  },
  {
    value: 'book illustrations',
    text: 'book illustrations',
    selected: false,
  },
  {
    value: 'case reports',
    text: 'case reports',
    selected: false,
  },
  {
    value: 'classical article',
    text: 'classical article',
    selected: false,
  },
  {
    value: 'clinical conference',
    text: 'clinical conference',
    selected: false,
  },
  {
    value: 'clinical study',
    text: 'clinical study',
    selected: false,
  },
  {
    value: 'clinical trial',
    text: 'clinical trial',
    selected: false,
  },
  {
    value: 'clinical trial protocol',
    text: 'clinical trial protocol',
    selected: false,
  },
  {
    value: 'clinical trial, phase i',
    text: 'clinical trial, phase i',
    selected: false,
  },
  {
    value: 'clinical trial, phase ii',
    text: 'clinical trial, phase ii',
    selected: false,
  },
  {
    value: 'clinical trial, phase iii',
    text: 'clinical trial, phase iii',
    selected: false,
  },
  {
    value: 'clinical trial, phase iv',
    text: 'clinical trial, phase iv',
    selected: false,
  },
  {
    value: 'clinical trial, veterinary',
    text: 'clinical trial, veterinary',
    selected: false,
  },
  {
    value: 'collected work',
    text: 'collected work',
    selected: false,
  },
  {
    value: 'comment',
    text: 'comment',
    selected: false,
  },
  {
    value: 'comparative study',
    text: 'comparative study',
    selected: false,
  },
  { value: 'congress', text: 'congress', selected: false },
  {
    value: 'consensus development conference',
    text: 'consensus development conference',
    selected: false,
  },
  {
    value: 'consensus development conference, nih',
    text: 'consensus development conference, nih',
    selected: false,
  },
  {
    value: 'controlled clinical trial',
    text: 'controlled clinical trial',
    selected: false,
  },
  {
    value: 'corrected and republished article',
    text: 'corrected and republished article',
    selected: false,
  },
  { value: 'dataset', text: 'dataset', selected: false },
  { value: 'dictionary', text: 'dictionary', selected: false },
  { value: 'directory', text: 'directory', selected: false },
  {
    value: 'duplicate publication',
    text: 'duplicate publication',
    selected: false,
  },
  {
    value: 'editorial',
    text: 'editorial',
    selected: false,
  },
  {
    value: 'electronic supplementary materials',
    text: 'electronic supplementary materials',
    selected: false,
  },
  {
    value: 'english abstract',
    text: 'english abstract',
    selected: false,
  },
  {
    value: 'ephemera',
    text: 'ephemera',
    selected: false,
  },
  {
    value: 'equivalence trial',
    text: 'equivalence trial',
    selected: false,
  },
  {
    value: 'evaluation studies',
    text: 'evaluation studies',
    selected: false,
  },
  {
    value: 'evaluation study',
    text: 'evaluation study',
    selected: false,
  },
  {
    value: 'expression of concern',
    text: 'expression of concern',
    selected: false,
  },
  {
    value: 'festschrift',
    text: 'festschrift',
    selected: false,
  },
  {
    value: 'government publication',
    text: 'government publication',
    selected: false,
  },
  { value: 'guideline', text: 'guideline', selected: false },
  {
    value: 'historical article',
    text: 'historical article',
    selected: false,
  },
  {
    value: 'interactive tutorial',
    text: 'interactive tutorial',
    selected: false,
  },
  { value: 'interview', text: 'interview', selected: false },
  {
    value: 'introductory journal article',
    text: 'introductory journal article',
    selected: false,
  },
  {
    value: 'journal article',
    text: 'journal article',
    selected: false,
  },
  { value: 'lecture', text: 'lecture', selected: false },
  {
    value: 'legal case',
    text: 'legal case',
    selected: false,
  },
  {
    value: 'legislation',
    text: 'legislation',
    selected: false,
  },
  { value: 'letter', text: 'letter', selected: false },
  {
    value: 'manuscript',
    text: 'manuscript',
    selected: false,
  },
  {
    value: 'meta analysis',
    text: 'meta analysis',
    selected: false,
  },
  {
    value: 'multicenter study',
    text: 'multicenter study',
    selected: false,
  },
  { value: 'news', text: 'news', selected: false },
  {
    value: 'newspaper article',
    text: 'newspaper article',
    selected: false,
  },
  {
    value: 'observational study',
    text: 'observational study',
    selected: false,
  },
  {
    value: 'observational study, veterinary',
    text: 'observational study, veterinary',
    selected: false,
  },
  { value: 'overall', text: 'overall', selected: false },
  {
    value: 'patient education handout',
    text: 'patient education handout',
    selected: false,
  },
  {
    value: 'periodical index',
    text: 'periodical index',
    selected: false,
  },
  {
    value: 'personal narrative',
    text: 'personal narrative',
    selected: false,
  },
  {
    value: 'pictorial work',
    text: 'pictorial work',
    selected: false,
  },
  {
    value: 'popular work',
    text: 'popular work',
    selected: false,
  },
  { value: 'portrait', text: 'portrait', selected: false },
  {
    value: 'practice guideline',
    text: 'practice guideline',
    selected: false,
  },
  {
    value: 'pragmatic clinical trial',
    text: 'pragmatic clinical trial',
    selected: false,
  },
  { value: 'preprint', text: 'preprint', selected: false },
  {
    value: 'publication components',
    text: 'publication components',
    selected: false,
  },
  {
    value: 'publication formats',
    text: 'publication formats',
    selected: false,
  },
  {
    value: 'published erratum',
    text: 'published erratum',
    selected: false,
  },
  {
    value: 'randomized controlled trial',
    text: 'randomized controlled trial',
    selected: false,
  },
  {
    value: 'randomized controlled trial, veterinary',
    text: 'randomized controlled trial, veterinary',
    selected: false,
  },
  {
    value: 'research support, american recovery and reinvestment act',
    text: 'research support, american recovery and reinvestment act',
    selected: false,
  },
  {
    value: 'research support, n i h , extramural',
    text: 'research support, n i h , extramural',
    selected: false,
  },
  {
    value: 'research support, n i h , intramural',
    text: 'research support, n i h , intramural',
    selected: false,
  },
  {
    value: 'research support, non u s gov t',
    text: 'research support, non u s gov t',
    selected: false,
  },
  {
    value: 'research support, u s gov t, non p h s',
    text: 'research support, u s gov t, non p h s',
    selected: false,
  },
  {
    value: 'research support, u s gov t, p h s',
    text: 'research support, u s gov t, p h s',
    selected: false,
  },
  {
    value: 'research support, u s government',
    text: 'research support, u s government',
    selected: false,
  },
  {
    value: 'retracted publication',
    text: 'retracted publication',
    selected: false,
  },
  {
    value: 'retraction of publication',
    text: 'retraction of publication',
    selected: false,
  },
  { value: 'review', text: 'review', selected: false },
  {
    value: 'scientific integrity review',
    text: 'scientific integrity review',
    selected: false,
  },
  {
    value: 'study characteristics',
    text: 'study characteristics',
    selected: false,
  },
  {
    value: 'study guide',
    text: 'study guide',
    selected: false,
  },
  {
    value: 'support of research',
    text: 'support of research',
    selected: false,
  },
  {
    value: 'systematic review',
    text: 'systematic review',
    selected: false,
  },
  {
    value: 'technical report',
    text: 'technical report',
    selected: false,
  },
  { value: 'twin study', text: 'twin study', selected: false },
  {
    value: 'validation study',
    text: 'validation study',
    selected: false,
  },
  {
    value: 'video audio media',
    text: 'video audio media',
    selected: false,
  },
  { value: 'webcast', text: 'webcast', selected: false },
]

export const PREPRINTS_OPTIONS = PREPRINTS.map((preprint) => ({
  value: preprint.name,
  text: preprint.name,
  selected: false,
}))

export const ARTICLE_ID_OPTIONS = [
  {
    value: 'DOI:*',
    text: 'DOI',
    selected: false,
  },
  {
    value: 'EXT_ID:* AND SRC:MED',
    text: 'PMID',
    selected: false,
  },
  {
    value: 'PMCID:*',
    text: 'PMCID',
    selected: false,
  },
  {
    value: 'EXT_ID:* AND SRC:PPR',
    text: 'Preprint',
    selected: false,
  },
  {
    value: 'ISSN:*',
    text: 'ISSN',
    selected: false,
  },
  {
    value: 'ISBN:*',
    text: 'ISBN',
    selected: false,
  },
]
