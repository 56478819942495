<script>
import { fetchBlog, fetchTechBlog } from '@/api'
import { Action } from 'epmc-patterns/components/v2'
import Blogs from '@/templates/static/Blogs'
import WindowResize from '@/helpers/window-resize'

const clientLogos = [
  {
    src: require('@/assets/use_cases/opencitations.svg'),
    alt: 'Open citations',
    href: '//opencitations.net/',
  },
  {
    src: require('@/assets/use_cases/PDBe.png'),
    alt: 'PDBe',
    href: '//www.ebi.ac.uk/pdbe/',
  },
  {
    src: require('@/assets/use_cases/VOSviewer.png'),
    alt: 'VOS viewer',
    href: '//www.vosviewer.com/',
  },
  {
    src: require('@/assets/use_cases/zenodo.png'),
    alt: 'ZENODO',
    href: '//zenodo.org/',
  },
  {
    src: require('@/assets/use_cases/symplectic.png'),
    alt: 'Symplectic',
    href: '//www.symplectic.co.uk/',
  },
  {
    src: require('@/assets/use_cases/F1000Research_Square.png'),
    alt: 'F1000 Research',
    href: 'https://f1000research.com/',
  },
]

const testimonials = [
  {
    title: 'On integrating Europe PMC with Alexa voice assistant apps',
    desc: 'Europe PMC has very functional API, with outstanding documentation. It is good for computers and great for humans. It was easy to get citations counts for a meta science project, after giving up on simlar APIs.',
    user: 'Tiago Lubiana',
    aff: 'Master’s student at Bioinformatics at University of São Paulo',
  },
  {
    title: 'On using mouse mine and Europe PMC to find IMPC alleles mentions',
    desc: 'I have worked with other literature services APIs like Scopus and WoS but there is no comparison with the service Europe PMC team provides.',
    user: 'Frederico Lopez Gomez',
    aff: 'Web developer at International Mouse Phenotyping Consortium',
  },
  {
    title: 'How Europe PMC helps IntAct curators assess underlying data',
    desc: 'Data links [from Europe PMC] help make our curation output visible for researchers, keeping the original source linked to a repository that ensures data preservation and updates.',
    user: 'Pablo Porras Millan',
    aff: 'Project leader at Intact',
  },
  {
    title: "Zetoc relies on Europe PMC's quality and dependability",
    desc: 'As part of the Zetoc service, we have utilised the EuropePMC API to supplement our data sources, in this case, highlighting openly available material. We require sources to be ‘production strength’, ie reliable and of sufficient quality. EuropePMC has proved to be such a quality source and highly dependable.',
    user: 'Zetoc team',
    aff: '',
  },
  {
    title: 'Europe PMC as a building block for OpenCitations',
    desc: 'The Europe PMC REST API is crucial in our workflow, since it allows us to get all the information about OA articles in Europe PMC plus their XML sources. Such sources are the first building block that enable the creation of the OpenCitations Corpus and the Open Biomedical Citations in Context Corpus.',
    user: 'Silvio Peroni',
    aff: 'Director at OpenCitations',
  },
  {
    title: 'Europe PMC supports important features for VOSviewer',
    desc: 'Europe PMC provides a highly flexible API. This is of great benefit to users of VOSviewer, who need only a few minutes to create powerful visualizations based on data from Europe PMC.',
    user: 'Nees Jan van Eck',
    aff: "Researcher at Leiden University's Centre for Science and Technology Studies",
  },
  {
    title: 'Europe PMC API helps unlock research potential at Zenodo',
    desc: 'We use EuropePMC API in our citation harvesting pipeline. Europe PMC has a well documented, reliable, and production-ready API allowing any user and service to build an open ecosystem of tools that empowers researchers.',
    user: 'Alex Loannidis',
    aff: 'Service manager at Zenodo',
  },
  {
    title: 'Europe PMC APIs allow high quality integration',
    desc: 'The EPMC API is well designed and well documented, and has allowed a high quality integration between Elements and Europe PubMed Central. It is frequently updated, but the changes are thoughtful and the versioning system is clear.',
    user: 'Symplectic, part of the Digital Science portfolio',
    aff: '',
  },
  {
    title: 'Europe PMC APIs help F1000 authenticate important data',
    desc: 'F1000 uses EMPC API’s on the Wellcome Open Research publishing platform. The API’s work faultlessly and they help us to authenticate important funding data as well as giving us the option to maximize Wellcome Open Research’s gateways usability.',
    user: 'Michael Markie',
    aff: 'Publishing Director at F1000 Platforms',
  },
].sort(() => Math.random() - 0.5)

export default {
  metaInfo() {
    return {
      title: 'API case studies - Developers - Europe PMC',
    }
  },
  components: { Action, Blogs },
  mixins: [WindowResize],
  data() {
    return {
      clientLogos,
      testimonialsOffset: 0,
      caseStudies: [],
    }
  },
  computed: {
    testimonialsScreenSize() {
      const { screenWidth } = this
      if (screenWidth >= 1199) {
        return 3
      } else if (screenWidth >= 799) {
        return 2
      } else {
        return 1
      }
    },
    prevNavDisabled() {
      return this.testimonialsOffset === 0
    },
    displayedTestimonials() {
      const { testimonialsOffset, testimonialsScreenSize } = this
      return testimonials.slice(
        testimonialsOffset,
        testimonialsOffset + testimonialsScreenSize
      )
    },
    nextNavDisabled() {
      return (
        this.testimonialsOffset + this.testimonialsScreenSize ===
        testimonials.length
      )
    },
  },
  async created() {
    const responses = await Promise.all([
      fetchBlog({ id: '51', snippetPos: [0, 2] }),
      fetchBlog({ id: '93' }),
      fetchTechBlog({ path: '2019-10-16-using-europe-pmc-restful-apis.md' }),
    ])
    this.caseStudies.push(...responses)
  },
  methods: {
    goToTestimonial(inc) {
      this.testimonialsOffset += inc
    },
  },
}
</script>
<template>
  <div id="use-case-page">
    <div class="grid-row">
      <div class="col-16">
        <h1>How people are using Europe PMC APIs</h1>
        <div id="intro">
          <p>
            The Europe PMC APIs are used by academic institutes, commercial
            industries, publishers, funders, infrastructure providers, text
            mining groups, open science apps and tools, and Research Information
            (CRIS) systems. The APIs are a powerful tool for programmatic users,
            enabling access to open content and metadata for building diverse
            applications for discovery of biomedical data.
          </p>
          <p>
            For more information about the APIs, see the
            <a href="/developers">Developers overview</a>, and the Europe PMC
            <a href="//europepmc.github.io/techblog/">Tech blog</a>.
          </p>
        </div>

        <hr class="thick" />
        <h2>Featured users</h2>
        <div id="featured-users">
          <a v-for="image in clientLogos" :key="image.alt" :href="image.href">
            <img :src="image.src" :alt="image.alt" />
          </a>
        </div>

        <hr class="thick" />
        <h2>What users say about the APIs</h2>
        <div id="testimonials">
          <div class="nav left">
            <action
              :disabled="prevNavDisabled"
              aria-label="Prev nav"
              @click="goToTestimonial(-1)"
            >
              <i slot="icon" class="fas fa-chevron-left"></i>
            </action>
          </div>
          <div class="nav right">
            <action
              :disabled="nextNavDisabled"
              aria-label="Next nav"
              @click="goToTestimonial(1)"
            >
              <i slot="icon" class="fas fa-chevron-right"></i>
            </action>
          </div>
          <div id="testimonials-carousel">
            <div
              v-for="testimonial in displayedTestimonials"
              :key="testimonial.title"
              class="testimonial"
            >
              <h3>{{ testimonial.title }}</h3>
              <p class="description">
                {{ testimonial.desc }}
              </p>
              <p class="small">
                {{ testimonial.user }}
                <template v-if="testimonial.aff">
                  <br />
                  {{ testimonial.aff }}
                </template>
              </p>
            </div>
          </div>
        </div>

        <hr class="thick" />
        <h2>API case studies</h2>
        <blogs :blogs="caseStudies" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#use-case-page {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
  #intro {
    padding: ($base-unit * 6) 12%;
    @media screen and (max-width: $breakpoint-extra-small) {
      padding: 0;
    }
  }
  #featured-users {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: $base-unit * 4;
    a {
      &:not(:last-child) {
        margin-right: $base-unit * 10;
      }
      img {
        margin-bottom: $base-unit * 3;
        max-width: 240px !important;
        @media screen and (max-width: $breakpoint-small) {
          width: 70%;
        }
      }
    }
  }
  #testimonials {
    position: relative;
    margin-bottom: $base-unit * 7;
    .nav {
      position: absolute;
      display: flex;
      height: 100%;
      align-items: center;
      &.right {
        right: 0;
      }
      span {
        font-size: $base-unit * 8;
      }
    }
    #testimonials-carousel {
      display: flex;
      flex-wrap: wrap;
      width: 96%;
      margin-left: 2%;
      @media screen and (max-width: $breakpoint-med) {
        width: 94%;
        margin-left: 3%;
      }
      @media screen and (max-width: $breakpoint-extra-small) {
        width: 92%;
        margin-left: 4%;
      }
      @media screen and (max-width: $breakpoint-smallest) {
        width: 88%;
        margin-left: 6%;
      }
      .testimonial {
        box-sizing: border-box;
        width: 32%;
        border: thin solid lighten($epmc-darker-grey, 10%);
        padding: $base-unit * 8;
        background-image: url('~@/assets/use_cases/bluebackground.svg');
        background-repeat: no-repeat;
        background-size: cover;
        &:not(:last-child) {
          margin-right: 2%;
        }
        &:nth-child(even) {
          background-image: url('~@/assets/use_cases/greenbackground.svg');
        }
        @media screen and (max-width: $breakpoint-med) {
          width: 49%;
        }
        @media screen and (max-width: $breakpoint-extra-small) {
          width: 100%;
          &:not(:last-child) {
            margin-right: 0;
          }
        }
        h3 {
          margin-top: 0;
        }
        .description {
          font-size: $base-unit * 4.5;
          line-height: $base-unit * 8;
          font-style: italic;
        }
      }
    }
  }
}
</style>
